import React from 'react';
import {File} from 'Common/File/File';
import {Space} from 'antd';
import {FilesUploadModal} from 'Common/FilesUploadModal/FilesUploadModal';
import {SingleFile} from 'Common/SingleFile/SingleFile';
import {WithSectionName} from 'hoc/WithSectionName';

export const Files = ({
  notification,
  setNotification,
}) => {
  return (
    <WithSectionName
      title='Файлы'
      orientation='left'
    >
      <div style={{marginBottom: 40}} >
        <Space size='middle' style={{display: 'flex', alignItems: 'baseline'}} >

          <FilesUploadModal
            onFileSelect={(file) => setNotification((prevData) => ({
              ...prevData,
              attachments: [...prevData.attachments, file],
            }))}
          >
            <File.Upload />
          </FilesUploadModal>

          {notification?.attachments?.map?.((item) => (
            <SingleFile
              file={item}
              key={item}
            />
          ))}

        </Space>

      </div>
    </WithSectionName>
  );
};
