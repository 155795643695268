import {Button, Checkbox, DatePicker, InputNumber, Space} from 'antd';
import {WithSectionName} from 'hoc/WithSectionName';
import React from 'react';
import locale from 'antd/es/date-picker/locale/ru_RU';
import moment from 'moment';
import {blue} from '@ant-design/colors';
import {DeleteOutlined} from '@ant-design/icons';

export const Parametres = ({
  handleChange,
  handleDateChange,
  parametres,
  handleAddDateChange,
  handleAddNewDate,
  handleRemoveDate,
}) => {
  return (
    <WithSectionName
      orientation='left'
      title='Параметры'
    >
      <Space direction='vertical' size='middle'>

        <Checkbox
          onChange={handleChange}
          name='send_just_now'
          checked={parametres.send_just_now}
        >
              Отправить сейчас
        </Checkbox>
        {!parametres.send_just_now &&
        parametres.date.map((currentDate, ind) => (
          <div key={ind}>
            Дата отправки: {' '}
            <DatePicker
              locale={locale}
              showTime
              onChange={handleAddDateChange(ind)}
              defaultValue={currentDate}
              key={currentDate}
            />
            {parametres.date.length > 1 && (
              <Button
                danger
                shape="circle"
                size='small'
                onClick={handleRemoveDate(ind)}
                style={{marginLeft: 16}}
                icon={<DeleteOutlined />}
              />
            )}
          </div>
        ))}
        {!parametres.send_just_now && (
          <Button
            type='text'
            color='primary'
            style={{
              color: blue.primary,
              marginTop: -8,
              marginBottom: 8,
            }}
            onClick={handleAddNewDate}
          >
         + Добавить дату
          </Button>
        )}
        <div>
          <Checkbox
            onChange={handleChange}
            name='isRepeat'
            checked={parametres.isRepeat}
          >
              Повторять каждые:
          </Checkbox>
          {parametres.isRepeat && (
            <>
              <InputNumber
                onChange={handleChange}
                size='small'
                style={{width: 46}}
                defaultValue={7}
                name='period'
              />
              {' '}дней
            </>
          )}
        </div>
        <div>
          <Checkbox
            onChange={handleChange}
            name='isEnd'
            checked={parametres.isEnd}
          >
              Конец периода:
          </Checkbox>
          {parametres.isEnd && (
            <DatePicker
              locale={locale}
              showTime
              name='lastDate'
              defaultValue={moment()}
              onChange={handleDateChange('lastDate')}
            />
          )}
        </div>
        <div>
          <Checkbox
            onChange={handleChange}
            name='isWeekendIncluded'
            checked={parametres.isWeekendIncluded}
          >
               Включая выходные
          </Checkbox>
        </div>
      </Space>
    </WithSectionName>
  );
};
