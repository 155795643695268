import {Empty, Modal, notification, Space, Spin} from 'antd';
import {deleteFile, getAllFiles, uploadFile} from 'api/files';
import {SingleFile} from 'Common/SingleFile/SingleFile';
import React, {useEffect, useState} from 'react';
import {File} from 'Common/File/File';

export const FilesUploadModal = ({
  onFileSelect,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [allFilesFetching, setAllFilesFetching] = useState(true);

  const getData = async () => {
    try {
      const {data} = await getAllFiles();
      setFiles(data.results);
    } catch (e) {
      console.error(e);
      notification.error({message: 'Ошибка загрузки списка файлов'});
    } finally {
      setAllFilesFetching(false);
    }
  };

  const handleDeleteFile = async (file) => {
    try {
      setIsLoading(file);
      await deleteFile(file);
      notification.success({message: 'Файл удалён'});
    } catch (e) {
      console.error(e);
      notification.error({message: 'Ошибка удаления файла'});
    } finally {
      setIsLoading(null);
    }
  };

  const handleFileUpload = async (e) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      try {
        setAllFilesFetching(true);
        await uploadFile(file);
        getData();
        notification.success({message: 'Файл загружен'});
      } catch (e) {
        console.error(e);
        notification.error({message: 'Ошибка загрузки файла'});
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        onClick={() => setOpen(true)}
      >
        {children}
      </div>
      <Modal
        title="Загрузка файлов"
        visible={open}
        footer={null}
        width={800}
        onCancel={() => setOpen(false)}
      >
        <div>
          <Space
            size='middle'
            style={{display: 'flex', alignItems: 'baseline'}}
          >
            <label>
              <File.Upload />
              <input
                type="file"
                accept=".pdf, .zip"
                className="none"
                hidden
                onChange={handleFileUpload}
              />
            </label>

            {allFilesFetching ?
                <div className='centered' ><Spin size='large' /></div> :
                files.length ?
                    (
                          files.map((file) => (
                            <SingleFile
                              file={file}
                              key={file}
                              isLoading={isLoading === file}
                              onDelete={handleDeleteFile}
                              onSelect={onFileSelect}
                            />
                          ))
                    ) :
                    <Empty />
            }

          </Space>

        </div>

      </Modal>
    </>
  );
};
