import {cyan, green} from '@ant-design/colors';
import {Button, Space} from 'antd';
import {
  SendNoificationModal,
} from 'Components/SendNotificationModal/SendNotificationModal';
import {UserMakeAdmin} from 'Components/UserModification/UserMakeAdmin';
import {UserModification} from 'Components/UserModification/UserModification';
import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {MyTable} from 'UI/Table';
import {Title} from 'UI/Title';


const Users = () => {
  const [open, setOpen] = useState(false);
  const [makeAdminId, setMakeAdminId] = useState(null);
  const [structureToMerge, setStructureToMerge] = useState(0);
  const history = useHistory();
  const tableRef = useRef(null);

  const handleOpen = (id) => () => {
    setStructureToMerge({target: {'group': [], 'employees': [id]}});
    setOpen(true);
  };

  const renderActions = (text, record) => (
    <Space size="middle">
      <Button
        type='primary'
        onClick={handleOpen(record.id)}
      >
          Уведомление
      </Button>
      <Button
        type='primary'
        style={{background: cyan.primary, border: cyan.primary}}
        onClick={() => history.push({
          pathname: `/users/${record.id}`,
          search: history.location.search,
        }, {employee: record})}
      >
          Редактировать
      </Button>
      {!record.isAdmin && (
        <Button
          type='primary'
          style={{background: green.primary, border: green.primary}}
          onClick={() => setMakeAdminId(record.id)}
        >
          + Администратор
        </Button>
      )}
    </Space>
  );

  const renderTelegram = (text, record) =>
    record.telegramNick || record.telegramID;

  return (
    <div>
      <Title>
        Сотрудники
      </Title>

      <MyTable
        ref={tableRef}
        columns={[
          {
            title: 'Телеграм',
            key: 'telegramID',
            render: renderTelegram,
          },
          {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Почта',
            dataIndex: 'email',
            key: 'email',
          },
          {
            title: 'Действие',
            key: 'action',
            render: renderActions,
          },
        ]}
        endpoint='/dashboard/employees'
        dataField='results'
      />

      <SendNoificationModal
        structureToMerge={structureToMerge}
        open={open}
        setOpen={setOpen}
      />

      <UserModification tableRef={tableRef} />
      <UserMakeAdmin open={makeAdminId} setOpen={setMakeAdminId} />

    </div>
  );
};

export default Users;
