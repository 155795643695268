import axios from 'axios';

export const instance = axios.create({
  withCredentials: true,
  baseURL: 'http://dashboard.inbis.api.famedev.ru/',
});

instance.interceptors.request.use(function(config) {
  // Do something before request is sent
  // eslint-disable-next-line max-len
  config.headers['Authorization'] = 'Bearer' + ' ' + (localStorage.getItem('token') || '');
  return config;
}, function(error) {
  // Do something with request error
  return Promise.reject(error);
});
