import moment from 'moment';
import {useState} from 'react';

const initialParametres = {
  date: [moment().add(3, 'minutes')],
  send_just_now: true,
  period: 7,
  lastDate: moment(),
  isWeekendIncluded: true,
  isRepeat: false,
  isEnd: false,
};


export const useHandleNotifications = () => {
  const [parametres, setParametres] = useState(initialParametres);

  const resetParametres = () => {
    setParametres(initialParametres);
  };

  const handleChange = (inst) => {
    if (inst.target) {
      setParametres((prev) => ({
        ...prev,
        [inst.target.name]: inst.target.checked ?? inst.target.value,
      }));
    }
  };

  const handleDateChange = (name) => (val) => {
    setParametres((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const handleAddDateChange = (ind) => (val) => {
    const newDates = [...parametres.date];
    console.log(newDates);
    setParametres((prevData) => ({
      ...prevData,
      date: newDates,
    }));
  };

  const handleAddNewDate = () => {
    setParametres((prevData) => ({
      ...prevData,
      date: [...prevData.date, moment()].filter((item) => !!item),
    }));
  };

  const handleRemoveDate = (deleteIndex) => () => {
    setParametres((prevData) => ({
      ...prevData,
      date: [...prevData.date]
          .filter((_, ind) => ind !== deleteIndex),
    }));
  };

  return ({
    parametres,
    handleChange,
    handleDateChange,
    initialParametres,
    resetParametres,
    handleAddDateChange,
    handleAddNewDate,
    handleRemoveDate,
  });
};
