import React from 'react';
import {
  FileOutlined,
} from '@ant-design/icons';
import {Spin} from 'antd';

export const SingleFile = ({
  file,
  isLoading,
  onSelect,
}) => {
  const handleClick = (currentFile) => (e) => {
    onSelect && onSelect(currentFile);
  };

  if (isLoading) {
    return <Spin style={{width: 80}} />;
  }

  return (
    <div
      className='file__wrapper'
      data-filename={file.fileName}
      onClick={handleClick(file)}
    >
      <FileOutlined style={{fontSize: 22}} />

      {/* {!!onDelete && (
        <div className={'file__delete'} onClick={handleDelete(file)} >
          <CloseCircleOutlined />
        </div>
      )} */}
    </div>
  );
};
